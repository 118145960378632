/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700|Roboto:300,400,500,600,700");
// Global vendors
@import "~bootstrap/dist/css/bootstrap.css";
// => Material
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";

@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
// TODO - could be imported only in components that use it
@import "~nouislider/dist/nouislider.min.css";
// Global fonts
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "./assets/plugins/flaticon/flaticon.css";
@import "./assets/plugins/flaticon2/flaticon.css";
@import "./assets/plugins/keenthemes-icons/font/ki.css";

// Metronic styles (replace these path when using RTL css below)
@import "./assets/sass/style.angular.scss";

// For RTL, check this documentation https://keenthemes.com/metronic/?page=docs&section=angular-rtl
// @import "./assets/sass/style.angular.rtl.css";

// Default Layout themes
@import "./assets/sass/themes/layout/header/base/light.scss";
@import "./assets/sass/themes/layout/header/menu/light.scss";
@import "./assets/sass/themes/layout/brand/dark.scss";
@import "./assets/sass/themes/layout/aside/dark.scss";

// Header themes
// .header-base-light {
//     @import "./assets/sass/themes/layout/header/base/light.scss";
// }

// .header-base-dark {
//     @import "./assets/sass/themes/layout/header/base/dark.scss";
// }

// Header Menu themes
// .header-menu-light {
//     @import "./assets/sass/themes/layout/header/menu/light.scss";
// }
// .header-menu-dark {
//     @import "./assets/sass/themes/layout/header/menu/dark.scss";
// }

// Brand themes
.brand-dark {
    @import "./assets/sass/themes/layout/brand/dark.scss";
}

.brand-light {
    @import "./assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// .aside-dark {
//   @import "./assets/sass/themes/layout/aside/dark.scss";
// }

// .aside-light {
//   @import "./assets/sass/themes/layout/aside/light.scss";
// }

// hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
    display: none !important;
}

.table-responsive {
    th {
        outline: none;

        &.sortable {
            .svg-icon-sort {
                opacity: 0;
            }

            &:hover {
                cursor: pointer;

                .svg-icon-sort {
                    opacity: 1;
                }
            }
        }
    }

    .table.table-head-custom thead th.sortable-active {
        color: $primary !important;
    }
}

.cursor-default {
    cursor: default !important;
}

.progress-modal {
    height: 3px;
    width: 100%;
}

.mat-snackbar-success {
    color: white;
    background-color: #28a745 !important;
}

.ans-table {
    table-layout: fixed;
    font-family: Poppins, Helvetica, "sans-serif" !important;
    min-width: min-content;
    thead {
        tr,
        mat-row,
        th,
        mat-header-cell {
            font-weight: $table-compact-head-font-weight;
            color: $table-compact-head-color !important;
            font-size: $table-compact-head-font-size;
            text-transform: $table-compact-head-text-transform;
            letter-spacing: $table-compact-head-letter-spacing;
        }
    }
    th,
    mat-header-cell,
    mat-cell,
    td {
        vertical-align: middle;
    }
}
.ans-table-600 {
    min-width: 600px;
}
.ans-table-0 {
    min-width: 0;
}

.ng-select.ng-select-solid > .ng-select-container {
    background-color: #f3f6f9;
    border-color: #f3f6f9;
    color: #3f4254;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease,
        box-shadow 0.15s ease;
}

.ng-select.ng-select-lg > .ng-select-container {
    height: calc(1.5em + 1.65rem + 2px);
    padding: 0.825rem 1.42rem;
    font-size: 1.08rem;
    line-height: 1.5;
    border-radius: 0.42rem;
    .ng-value-container {
        .ng-input {
            top: 10px;
        }
    }
}

.mat-no-rows {
    color: #9c9c9c;
    font-size: 12px;
}

.filesystem-tree-wrapper {
    overflow-x: auto;
    overflow-y: auto;
}

/* Always show scroll bar */
body {
    position: relative;
    overflow-y: scroll;
}

.icon-button {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    border: 0;
    background: transparent;
}

.box-select {
    border-radius: 0.42rem;
    border: 1px dashed #e4e6ef;
    cursor: pointer;
    &:hover {
        background-color: #f1faff;
        border-color: #009ef7;
    }
}

.box-select.selected {
    background-color: #f1faff;
    border-color: #009ef7;
    .when-selected-text-blue {
        color: #009ef7;
    }
}

.card.card-dashed {
    box-shadow: none;
    border: 1px dashed #e4e6ef;
}

@media (max-width: 768px) {
    .mat-table[appmattableresponsive] {
        min-width: 100% !important;
        .mat-header-row {
            display: flex;
            flex-wrap: wrap;
            height: auto;
            position: sticky;
            top: 0;
            z-index: 2;

            th.mat-header-cell {
                display: flex;
                flex: 1;
                align-items: center;
                padding: 1rem 0.5rem;
                justify-content: space-around;

                & > .mat-sort-header-container {
                    padding-left: 15px; // padding is used to center the header table cells, due to the width of the sort icon
                }

                &:not([mat-sort-header]),
                &.mat-sort-header-disabled,
                &.mat-column-actions {
                    display: none;
                }

                &.mat-column-select {
                    display: table-cell;
                    flex-shrink: 1;
                    flex-grow: 0;
                }
            }
        }

        .mat-row {
            height: auto;

            td.mat-cell:not(.mat-column-actions, .mat-column-select) {
                width: 100%;
                display: grid;
                grid-template-columns: 50% 50%;
                padding: 0.5rem 0;
                text-align: left;
                word-break: break-all;

                & > * {
                    grid-column: 2;
                }

                &:before {
                    content: attr(data-column-name);
                    text-align: left;
                }
            }

            td.mat-column-actions,
            td.mat-column-select {
                display: flex;
                justify-content: center;
                padding: 0 0.5rem;
                width: 100%;

                & > * {
                    margin: $spacer * 0.5 $spacer !important;
                }
            }

            &:last-of-type td:last-of-type {
                border: none;
            }

            &:not(:last-of-type) td:last-of-type {
                border-bottom: 0.5rem solid $secondary;
                width: 100%;
            }
        }
    }

    .mat-card,
    .mat-expansion-panel {
        .mat-table[mattableresponsive] {
            border: 0.5rem solid $secondary;
        }
    }
}

.package {
    border-radius: 0.42rem;
    cursor: pointer;
    box-shadow: 0 0 25px 5px transparent;
    border: 1px dashed #e4e6ef;
}

.package:hover {
    background-color: #f0f3f8 !important;
}

.package.selected {
    box-shadow: 0 0 25px 5px dodgerblue;
}

.info-box-gray {
    // color: #7e8299;
    font-size: 1.3rem;
    border-radius: 0.42rem;
    border: 2px dashed #e4e6ef;
    padding: 2rem;
    margin-bottom: 2rem;
}

.info-box {
    color: #7e8299;
    background-color: #f1faff;
    font-size: 1.1rem;
    border-radius: 0.42rem;
    border: 2px dashed #00a3ff;
    padding: 2rem;
    margin-bottom: 2rem;
}
.line-through {
    text-decoration: line-through;
}
.disabled-router-link {
    pointer-events: none;
    cursor: default;
    color: unset;
}

.buttons-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
}

.input-group-prepend {
    .ng-select-container {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    label {
        margin-bottom: 0 !important;
    }
}
.input-group-append {
    .ng-select-container {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    label {
        margin-bottom: 0 !important;
    }
}

.scrollable-card-in-modal {
    .card-body {
        overflow-y: auto;
        max-height: 80vh;
    }
}

.label-container .form-group {
    margin-bottom: 0;
}

.btn.btn-success:disabled {
    color: #3f4254;
    cursor: not-allowed;
}

.multiline-label {
    padding: 0.46rem 0.8rem;
    border-radius: 0.46rem;
    width: auto;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    font-size: 1rem;
    color: #3f4254;
    font-weight: 400;
}
